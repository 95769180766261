import * as Yup from 'yup';

interface LoginFormValues {
  login: string;
  password: string;
}

const loginSchema = Yup.object().shape({
  login: Yup.string().required('Login is required'),
  password: Yup.string().required('Password is required'),
});

export const validationSchemaLogin: Yup.Schema<LoginFormValues> = loginSchema;
