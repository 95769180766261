import {ICategoryDTO, IItemDTO} from '@/types';
import axios from 'axios';
import {setAuthToken} from './setAuthToken ';

export const updateCurrentRow = async (
  url: string,
  data: IItemDTO | ICategoryDTO
): Promise<any> => {
  try {
    const token = localStorage.getItem('jwtToken');
    if (!token) {
      throw new Error('No token found in localStorage');
    }
    setAuthToken(token);

    console.log('URL:', url);
    console.log('Data:', data);
    console.log('Token:', token);
    const id = data.id;
    delete data?.id;
    delete data?.createdAt;
    const response = await axios.put<Promise<any>>(`${url}/${id}`, data, {
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });

    return response.data;
  } catch (error: any) {
    // Handle errors with detailed logging
    if (error.response) {
      // Server responded with a status other than 200 range
      console.error('Response data:', error.response.data);
      console.error('Response status:', error.response.status);
      console.error('Response headers:', error.response.headers);
    } else if (error.request) {
      // Request was made but no response was received
      console.error('Request data:', error.request);
    } else {
      // Something else happened in setting up the request
      console.error('Error message:', error.message);
    }
    throw error;
  }
};
