import React from 'react';
import {Navigate} from 'react-router-dom';

interface IProtectedRouteProps {
  children: React.ReactElement;
}

const ProtectedRoute: React.FC<IProtectedRouteProps> = ({children}) => {
  const isAuthenticated: boolean = localStorage.getItem('jwtToken') !== null;

  return isAuthenticated ? <>{children}</> : <Navigate to="/" />;
};

export default ProtectedRoute;
