import Box from '@mui/material/Box';
import React, {PropsWithChildren} from 'react';

interface TabPanelProps extends PropsWithChildren {
  index: number;
  value: number;
  dir: any;
}

export const TabPanel: React.FC<TabPanelProps> = ({
  children,
  value,
  index,
  dir,
}) => {
  return (
    <div
      role={'tabpanel'}
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      dir={dir}
      style={{overflow: 'hidden', height: '100vh'}}
    >
      {value === index && (
        <Box sx={{p: 1, width: '100%'}}>
          <div style={{display: 'flex', justifyContent: 'center'}}>
            {children}
          </div>
        </Box>
      )}
    </div>
  );
};
