import {useContext} from 'react';
import {CategoriesContext} from './categoriesContext';

const useCategories = () => {
  const context = useContext(CategoriesContext);
  if (!context)
    throw new Error('useCategories must be used within a CategoriesContext');

  return context;
};

export {useCategories};
