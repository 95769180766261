import {CustomFieldPropsDTO} from '@/types';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import {TextField} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import {ErrorMessage, Field} from 'formik';
import React, {useState} from 'react';
import './CustomInputs.css';

export const CustomTextField: React.FC<CustomFieldPropsDTO> = ({
  info,
  disabled = false,
  error,
  type,
}) => {
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event: React.MouseEvent) => {
    event.preventDefault();
  };

  const isPasswordField = info.type === 'password';

  return (
    <div className={type ? 'custemInputLogin' : 'custemInput'}>
      <Field
        as={TextField}
        {...info}
        sx={{m: 1, width: '100%'}}
        variant={'standard'}
        disabled={disabled}
        type={isPasswordField && !showPassword ? 'password' : 'text'}
        InputProps={{
          endAdornment: isPasswordField ? (
            <InputAdornment position={'end'}>
              <IconButton
                aria-label={'toggle password visibility'}
                onClick={handleClickShowPassword}
                onMouseDown={handleMouseDownPassword}
              >
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          ) : undefined,
        }}
      />
      <ErrorMessage className={'error'} name={info.name} component={'div'} />
    </div>
  );
};
