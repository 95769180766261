export const styleCategoriesBox = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  padding: '16px',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'start',
  p: 2,
  bgcolor: '#ffffff',
  borderRadius: '8px',
  boxShadow: '0 0px 37px 14px rgba(0, 0, 0, 0.1)',
};
