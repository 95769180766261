import {useContext} from 'react';
import {ProductsContext} from './productsContext';

const useProducts = () => {
  const context = useContext(ProductsContext);
  if (!context)
    throw new Error('useProducts must be used within a ProductsContext');

  return context;
};

export {useProducts};
