import CheckIcon from '@mui/icons-material/Check';
import SaveIcon from '@mui/icons-material/Save';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Fab from '@mui/material/Fab';
import {green, red} from '@mui/material/colors';
import * as React from 'react';
import {useContext, useEffect, useRef, useState} from 'react';
import {SnackbarContext} from '../../contexts/snackbarContext';
import './ProgressBar.css';

interface IProgressBarProps {
  isLoading: boolean;
  success: boolean;
}

export const ProgressBar: React.FC<IProgressBarProps> = ({
  isLoading,
  success,
}) => {
  const [isVisible, setIsVisible] = useState<boolean>(false);
  const {showSnackbar} = useContext(SnackbarContext);
  const timer = useRef<any>();

  useEffect(() => {
    if (isLoading && !isVisible) {
      setIsVisible(true);
    }
    if (!isLoading && isVisible) {
      timer.current = setTimeout(() => {
        setIsVisible(false);
      }, 1000);
    }
    if (success) {
      showSnackbar!('Success!', 'success');
    } else if (!isLoading && isVisible) {
      showSnackbar!('Error!', 'error');
    }
  }, [isLoading, success]);

  useEffect(() => {
    return () => {
      clearTimeout(timer.current);
    };
  }, []);

  const renderIcon = () => {
    let content = null;
    let style = {};

    if (isLoading) {
      content = (
        <SaveIcon sx={{position: 'relative', height: '50%', width: '50%'}} />
      );
    } else if (success) {
      content = (
        <CheckIcon
          sx={{
            position: 'relative',
            height: '50%',
            width: '50%',
          }}
        />
      );
      style = {
        'bgcolor': green[700],

        '&:hover': {
          bgcolor: green[700],
        },
      };
    } else {
      content = (
        <WarningAmberIcon
          sx={{position: 'relative', height: '50%', width: '50%'}}
        />
      );
      style = {
        'bgcolor': red[700],

        '&:hover': {
          bgcolor: red[700],
        },
      };
    }

    return (
      <Fab
        aria-label={'save'}
        color={'primary'}
        sx={{height: '100%', width: '100%', ...style}}
      >
        {content}
      </Fab>
    );
  };

  return isVisible ? (
    <Box>
      {renderIcon()}
      {isLoading && (
        <CircularProgress
          size={130}
          sx={{
            color: green[500],
            position: 'absolute',
            top: -4,
            left: -4,
            zIndex: 1,
          }}
        />
      )}
    </Box>
  ) : null;
};
