import {ICategoryDTO} from '@/types';
import axios from 'axios';
import {categoriesURL} from './urls';

export const getCategories = async (): Promise<ICategoryDTO[]> => {
  try {
    const response = await axios.get<ICategoryDTO[]>(categoriesURL);

    return response.data;
  } catch (error) {
    console.error('Error fetching products:', error);
    throw new Error('bad Request');
  }
};
