import EditIcon from '@mui/icons-material/Edit';
import {Box, Button, Modal} from '@mui/material';
import React, {useEffect, useState} from 'react';
import {Aligner} from '../../../core-component/aligner';
import {getIcons} from '../../../service/getIcons';

export const stylesInputsBox = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '43%',
  // height: '59%',
  padding: '60px',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  p: 7.7,
  bgcolor: '#ffffff',
  borderRadius: '8px',
  boxShadow: '0 0px 37px 14px rgba(0, 0, 0, 0.1)',
};

const IconInput: React.FC<any> = (props) => {
  const [icons, setIcons] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  // const [selectedIcon, setSelectedIcon] = useState(props.value[0]);

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    const newIcons = await getIcons();
    setIcons(newIcons);
  };

  const openModal = () => {
    setIsOpen(true);
  };

  const onCloseModal = () => {
    setIsOpen(false);
  };

  const chooseIcon = (icon: string) => () => {
    // setSelectedIcon(icon);
    const event = {
      target: {
        name: 'images',
        value: [icon],
      },
    };
    props!.onChange(event);
    onCloseModal();
  };

  return (
    <>
      <Box {...props} onClick={openModal} style={{cursor: 'pointer'}}>
        <Aligner>
          <img width={30} height={30} src={props.value[0]} />
          <EditIcon fontSize={'small'} />
        </Aligner>
      </Box>
      <Modal open={isOpen} onClose={onCloseModal}>
        <Box sx={stylesInputsBox} className={'icon-input__modal'}>
          {icons.map((icon: string) => {
            return (
              <Button onClick={chooseIcon(icon)}>
                <img width={30} height={30} src={icon} />
              </Button>
            );
          })}
        </Box>
      </Modal>
    </>
  );
};

export {IconInput};
