import ImageGallery from "react-image-gallery";
import { useState, useEffect } from "react";
import "react-image-gallery/styles/css/image-gallery.css";
import "./MyGalery.css";
import IconButton from "@mui/material/IconButton";
import ClearSharpIcon from "@mui/icons-material/ClearSharp";
import { Dispatch, SetStateAction } from "react";

interface MyGaleryProps {
  disabled: boolean;
  setImagesArr: Dispatch<SetStateAction<any[]>>;
  images: any[];
}

export const MyGalery: React.FC<MyGaleryProps> = ({
  images,
  setImagesArr,
  disabled,
}) => {
  const [initialImages, setInitialImages] = useState([]);
  useEffect(() => {
    const img: any = images?.map((image, index) => {
      if (typeof image === "string") {
        return {
          id: Math.random(),
          original: image,
          thumbnail: image,
        };
      } else {
        return {
          id: image?.id,
          original: image?.preview,
          thumbnail: image?.preview,
        };
      }
    });
    setInitialImages(img);
  }, [images]);

  const removeImage = (item: any) => {
    console.log("item", item, images);
    setImagesArr(
      images.filter((image) => {
        return image !== item.original;
      })
    );
  };

  return (
    <div className="imageGalery">
      <ImageGallery
        items={initialImages || []}
        showBullets={false}
        showIndex={false}
        lazyLoad={false}
        showPlayButton={false}
        showNav={true}
        showFullscreenButton={false}
        thumbnailPosition={"bottom"}
        renderItem={(item: any) => (
          <div className="image-gallery-image">
            <img src={item.original} alt="" />
            {item.original !==
              "https://www.tenstickers-australia.com/build/images/web/services/upload.jpg" && (
              <IconButton
                style={{
                  position: "absolute",
                  right: 0,
                  top: 0,
                }}
                className="button-remove"
                disabled={disabled}
                onClick={() => removeImage(item)}
                aria-label="delete"
                size="small"
                edge="end"
              >
                <ClearSharpIcon color="secondary" fontSize="large" />
              </IconButton>
            )}
          </div>
        )}
      />
    </div>
  );
};
