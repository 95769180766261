import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import {useTheme} from '@mui/material/styles';
import {useState} from 'react';
import SwipeableViews from 'react-swipeable-views';
import {CategoriesTab} from '../categories-tab/CategoriesTab';
import {ProductsTab} from '../products-tab/ProductsTab';
import {TabPanel} from './TabPanel';

export const AdminPanel: React.FC = () => {
  const theme = useTheme();
  const [tabIndex, setTabIndex] = useState<number>(0);

  const changeTabIndex = (_: React.ChangeEvent<{}>, newTabIndex: number) => {
    setTabIndex(newTabIndex);
  };

  return (
    <Box
      sx={{
        bgcolor: 'background.paper',
        width: '100%',
      }}
    >
      <AppBar position={'static'}>
        <Tabs
          value={tabIndex}
          onChange={changeTabIndex}
          indicatorColor={'secondary'}
          textColor={'inherit'}
        >
          <Tab label={'Categories'} />
          <Tab label={'Products'} />
        </Tabs>
      </AppBar>
      <SwipeableViews
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={tabIndex}
      >
        <TabPanel value={tabIndex} index={0} dir={theme.direction}>
          <CategoriesTab />
        </TabPanel>
        <TabPanel value={tabIndex} index={1} dir={theme.direction}>
          <ProductsTab />
        </TabPanel>
      </SwipeableViews>
    </Box>
  );
};
