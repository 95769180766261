import Button from '@mui/joy/Button';
import DialogActions from '@mui/joy/DialogActions';
import {Form, Formik} from 'formik';
import {CustomIconField} from './customInputs/CustomIconField';
import {CustomNumberField} from './customInputs/CustomNumberField';
import {CustomSelector} from './customInputs/CustomSelector';
import {CustomTextArea} from './customInputs/CustomTextArea';
import {CustomTextField} from './customInputs/CustomTextField';
import './formik.css';

interface MyFormikProps {
  disabled: boolean;
  initialValues: any;
  inputState: any;
  validationSchema: any;
  handleFormik: any;
  onClose: any;
  className?: string;
}

export const MyFormik: React.FC<MyFormikProps> = ({
  validationSchema,
  inputState,
  initialValues,
  handleFormik,
  disabled,
  onClose,
  className,
}) => {
  const renderInput = (info: any, errors: any) => {
    let input = null;

    switch (info.type) {
      case 'text':
        input = (
          <CustomTextField
            key={info.id}
            error={errors[info.name]}
            info={info}
            disabled={disabled}
          />
        );
        break;
      case 'number':
        input = (
          <CustomNumberField
            key={info.id}
            error={errors[info.name]}
            info={info}
            disabled={disabled}
          />
        );
        break;
      case 'select':
        input = (
          <CustomSelector
            key={info.id}
            error={errors[info.name]}
            info={info}
            disabled={disabled}
          />
        );
        break;
      case 'description':
        input = (
          <CustomTextArea
            key={info.id}
            error={errors[info.name]}
            info={info}
            disabled={disabled}
          />
        );
        break;
      case 'icon':
        input = (
          <CustomIconField
            key={info.id}
            error={errors[info.name]}
            info={info}
            disabled={disabled}
          />
        );
    }

    return input;
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleFormik}
      class={className}
    >
      {({errors}) => (
        <Form className={`form ${className}`}>
          {inputState.map(renderInput, errors)}
          <DialogActions>
            <Button
              disabled={disabled}
              type={'submit'}
              variant={'solid'}
              color={'primary'}
            >
              {'Save'}
            </Button>
            <Button
              disabled={disabled}
              variant={'plain'}
              color={'neutral'}
              onClick={onClose}
            >
              {'Cancel'}
            </Button>
          </DialogActions>
        </Form>
      )}
    </Formik>
  );
};
