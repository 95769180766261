import WarningRoundedIcon from '@mui/icons-material/WarningRounded';
import Button from '@mui/joy/Button';
import DialogActions from '@mui/joy/DialogActions';
import DialogContent from '@mui/joy/DialogContent';
import DialogTitle from '@mui/joy/DialogTitle';
import Divider from '@mui/joy/Divider';
import ModalDialog from '@mui/joy/ModalDialog';
import Modal from '@mui/material/Modal';
import React from 'react';

interface RemoveItemModalProps {
  isOpen: boolean;
  onClose: () => void;
  removeItem: () => Promise<void>;
}

export const RemoveItemModal: React.FC<RemoveItemModalProps> = ({
  isOpen,
  onClose,
  removeItem,
}) => {
  return (
    <Modal open={isOpen} onClose={onClose}>
      <ModalDialog variant={'outlined'} role={'alertdialog'}>
        <DialogTitle>
          <WarningRoundedIcon />
          {'Confirmation'}
        </DialogTitle>
        <Divider />
        <DialogContent>
          {'Are you sure you want to delete this item?'}
        </DialogContent>
        <DialogActions>
          <Button variant={'solid'} color={'danger'} onClick={removeItem}>
            {'Delete'}
          </Button>
          <Button variant={'plain'} color={'neutral'} onClick={onClose}>
            {'Cancel'}
          </Button>
        </DialogActions>
      </ModalDialog>
    </Modal>
  );
};
