import React, {
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  createContext,
  useEffect,
  useState,
} from 'react';
import {getCategories} from '../service/getCategories';
import {ICategoryDTO} from '../types';

const CategoriesContext = createContext<{
  categories?: ICategoryDTO[];
  setCategories?: Dispatch<SetStateAction<ICategoryDTO[]>>;
}>({});

const CategoriesContextProvider: React.FC<PropsWithChildren> = ({children}) => {
  const [categories, setCategories] = useState<ICategoryDTO[]>([]);

  const fetchCategories = async (): Promise<any> => {
    try {
      const newCategories: ICategoryDTO[] = await getCategories();

      setCategories(newCategories);
    } catch (err: any) {
      console.error(err.message);
    }
  };

  useEffect(() => {
    fetchCategories();
  }, []);

  return (
    <CategoriesContext.Provider value={{categories, setCategories}}>
      {children}
    </CategoriesContext.Provider>
  );
};

export {CategoriesContext, CategoriesContextProvider};
