import {CustomFieldPropsDTO} from '@/types';
import {TextField} from '@mui/material';
import {ErrorMessage, Field} from 'formik';
import './CustomInputs.css';

export const CustomNumberField: React.FC<CustomFieldPropsDTO> = ({
  error,
  info,
  disabled,
}) => {
  return (
    <div className={'custemInput'}>
      <Field
        variant={'standard'}
        as={TextField}
        sx={{m: 1, width: '100%'}}
        error={Boolean(error)}
        {...info}
        disabled={disabled}
      />
      <ErrorMessage className={'error'} name={info.name} component={'div'} />
    </div>
  );
};
