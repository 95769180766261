import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import EditIcon from '@mui/icons-material/Edit';
import {GridActionsCellItem} from '@mui/x-data-grid';

export const ColumnsCategories = (
  handleDeleteClick: (id: string) => void,
  handleEditClick: (id: string) => void
) => {
  return [
    {
      field: 'images',
      headerClassName: 'super-app-theme--header',
      headerName: 'Icon',
      cellClassName: 'super-app-theme--cell',
      flex: 1,
      type: 'text',
      renderCell: (p: any) => {
        return <img width={30} height={30} src={p?.formattedValue?.[0]} />;
      },
    },
    {
      field: 'name',
      headerClassName: 'super-app-theme--header',
      headerName: 'Name',
      cellClassName: 'super-app-theme--cell',
      flex: 1,
      type: 'text',
    },

    {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      headerClassName: 'super-app-theme--header',
      flex: 1,
      cellClassName: 'actions1',
      getActions: ({id}: any) => {
        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            className="textPrimary"
            onClick={() => handleEditClick(id)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            onClick={() => handleDeleteClick(id)}
            color="inherit"
          />,
        ];
      },
    },
  ];
};
