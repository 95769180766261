export const inputInfoProduct = [
  { label: "Name", name: "name", type: "text", id: "name" },
  { label: "Price", name: "price", type: "number", id: "price" },
  { label: "Category", name: "categoryId", type: "select", id: "categoryId" },
  { label: "Quantity", name: "quantity", type: "number", id: "quantity" },
  {
    label: "Description",
    name: "description",
    type: "description",
    id: "description",
  },
];
