import {SnackbarAlertDTO} from '@/types';
import Alert, {AlertProps} from '@mui/material/Alert';
import MUISnackbar from '@mui/material/Snackbar';
import React from 'react';

const Snackbar: React.FC<SnackbarAlertDTO> = ({
  open,
  message,
  severity,
  onClose,
}) => {
  return (
    <MUISnackbar open={open} autoHideDuration={6000} onClose={onClose}>
      <Alert
        onClose={onClose}
        severity={severity as AlertProps['severity']}
        variant={'filled'}
        sx={{width: '100%'}}
      >
        {message}
      </Alert>
    </MUISnackbar>
  );
};

export {Snackbar};
