import {Button} from '@mui/material';
import {Form, Formik, FormikHelpers} from 'formik';

import './Login.css';
import {inputInfoLogin} from './inputInfoLogin';
import {intialStateLoginInput} from './initialStateLogin';
import {validationSchemaLogin} from './validationScemaLogin';
import {CustomTextField} from '../Modals/customInputs/CustomTextField';

interface LoginFormProps {
  onLogin: (username: string, password: string) => void;
}

export const LoginForm: React.FC<LoginFormProps> = ({onLogin}) => {
  const handleSubmit = (
    values: {login: string; password: string},
    {setSubmitting}: FormikHelpers<{login: string; password: string}>
  ) => {
    const {login, password} = values;
    onLogin(login, password);
  };

  return (
    <div className="container card border pt-5 pb-5">
      <h1 className="text-center">LogIn</h1>
      <Formik
        initialValues={intialStateLoginInput}
        validationSchema={validationSchemaLogin}
        onSubmit={handleSubmit}
      >
        {({errors, touched}) => (
          <Form autoComplete={'off'}>
            {inputInfoLogin.map((info) => {
              return info.gen === 'aa' ? (
                <CustomTextField type="login" key={info.id} info={info} />
              ) : null;
            })}
            <Button type="submit">Login</Button>
          </Form>
        )}
      </Formik>
    </div>
  );
};
