import { Field, ErrorMessage } from "formik";
import { TextField } from "@mui/material";
import "./CustomInputs.css";
import { CustomFieldPropsDTO } from "@/types";
import React from "react";

export const CustomTextArea: React.FC<CustomFieldPropsDTO> = ({
  error,
  info,
  disabled,
}) => {
  return (
    <div className="custemInput">
      <Field
        error={Boolean(error)}
        as={TextField}
        {...info}
        sx={{ m: 1, width: "100%" }}
        multiline
        minRows={4}
        maxRows={6}
        variant="standard"
        disabled={disabled}
      />
      <ErrorMessage className="error" name={info.name} component="div" />
    </div>
  );
};
