import {useContext} from 'react';
import {useNavigate} from 'react-router';

import {SnackbarContext} from '../../contexts/snackbarContext';
import {authorization} from '../../service/jwtauthorization';
import {LoginForm} from './LoginForm';

export const Login: React.FC = () => {
  const navigate = useNavigate();
  const {showSnackbar} = useContext(SnackbarContext);

  const handleLogin = async (username: string, password: string) => {
    try {
      await authorization(username, password);
      showSnackbar!('Login successful!', 'success');
      navigate('/home');
    } catch {
      showSnackbar!('Invalid Login or Password!', 'error');
    }
  };

  return (
    <div
      style={{
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        background: `url(
          "https://img.freepik.com/free-vector/realistic-luxury-background_23-2149319847.jpg?size=626&ext=jpg&ga=GA1.1.553209589.1715040000&semt=ais"
        )`,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
      }}
    >
      <LoginForm onLogin={handleLogin} />
    </div>
  );
};
