import {ICategoryDTO, IItemDTO} from '@/types';
import axios from 'axios';
import {setAuthToken} from './setAuthToken ';

export const setNewRows = async (
  url: string,
  data: ICategoryDTO | IItemDTO
): Promise<any> => {
  try {
    const token = localStorage.getItem('jwtToken');
    if (!token) {
      throw new Error('No token found in localStorage');
    }
    setAuthToken(token);

    const response = await axios.post<Promise<any>>(url, data, {
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });
    console.log('Response:', response);

    return response.data;
  } catch (error: any) {
    console.error('Response data:', error.response);
    throw error;
  }
};
