import { setAuthToken } from "./setAuthToken ";
import axios from "axios";
export const removeCurrentRow = async (
  url: string,
  id: string | undefined
): Promise<any> => {
  try {
    const token = localStorage.getItem("jwtToken");
    if (!token) {
      throw new Error("No token found in localStorage");
    }
    setAuthToken(token);
    console.log("URL:", url);
    console.log("Data:", id);
    console.log("Token:", token);

    const response = await axios.delete<Promise<any>>(`${url}/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });

    console.log("Response:", response);
    return response.data;
  } catch (error: any) {
    if (error.response) {
      console.error("Response data:", error.response.data);
      console.error("Response status:", error.response.status);
      console.error("Response headers:", error.response.headers);
    } else if (error.request) {
      console.error("Request data:", error.request);
    } else {
      console.error("Error message:", error.message);
    }
    throw error;
  }
};
