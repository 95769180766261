import React from 'react';
import {BrowserRouter, Route, Routes} from 'react-router-dom';
import './App.css';
import {AdminPanel} from './components/Tabs/AdminPanel';
import {Login} from './components/login/Login';
import ProtectedRoute from './components/login/ProtectedRouter';
import {CategoriesContextProvider} from './contexts/categoriesContext';
import {ProductsContextProvider} from './contexts/productsContext';
import {SnackbarContextProvider} from './contexts/snackbarContext';
import {NotFound} from './core-component/not-found/NotFound';

const App: React.FC = () => {
  return (
    <div className={'App'}>
      <BrowserRouter>
        <CategoriesContextProvider>
          <ProductsContextProvider>
            <SnackbarContextProvider>
              <Routes>
                <Route path={'*'} element={<NotFound />} />
                <Route path={'/'} element={<Login />} />
                <Route
                  path={'/home'}
                  element={
                    <ProtectedRoute>
                      <AdminPanel />
                    </ProtectedRoute>
                  }
                />
              </Routes>
            </SnackbarContextProvider>
          </ProductsContextProvider>
        </CategoriesContextProvider>
      </BrowserRouter>
    </div>
  );
};

export default App;
