import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import Button from '@mui/joy/Button';
import { styled } from '@mui/material/styles';
import axios from 'axios';
import { filesURL } from '../../../service/urls';

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

const upload = async (file) => {
  const formData = new FormData();

  formData.append('image', file);
  const token = localStorage.getItem('jwtToken');

  const response = await axios.post(filesURL, formData, {
    headers: {
      'Authorization': `Bearer ${token}`,
      'Content-Type': 'multipart/form-data',
    },
  });
  console.log('🚀 ~ upload ~ response:', response.data);

  return response.data;
};

export const ImageDownloader = ({ disabled, setImagesArr }) => {
  const handleFileChange = async (event) => {
    const selectedFiles = Array.from(event.target.files);

    console.log('selectedFiles', selectedFiles);
    const requests = selectedFiles.map((data, index) => {
      const file = data;
      console.log('file', file);

      return upload(file);
    });

    let urls;

    await Promise.all(requests).then((data) => {
      urls = data.map((d) => d.url);
    });

    setImagesArr((prev) => [...prev, ...urls]);
  };

  return (
    <>
      <Button
        disabled={disabled}
        variant="outlined"
        component="label"
        tabIndex={-1}
        startDecorator={<CloudUploadIcon />}
      >
        Upload image
        <VisuallyHiddenInput onChange={handleFileChange} multiple type="file" />
      </Button>
    </>
  );
};
