export const stylesProductsBox = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '43%',
  padding: '60px',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  p: 7.7,
  bgcolor: '#ffffff',
  borderRadius: '8px',
  boxShadow: '0 0px 37px 14px rgba(0, 0, 0, 0.1)',
};
