import axios from 'axios';
import {setAuthToken} from './setAuthToken ';
import {authSigninURL} from './urls';

export async function authorization(username, password) {
  try {
    const response = await axios.post(authSigninURL, {
      username,
      password,
    });

    const {accessToken} = response.data;
    localStorage.setItem('jwtToken', accessToken);
    setAuthToken(accessToken);

    return accessToken;
  } catch (error) {
    if (error.response) {
      console.error('Login failed:', error.response.data);
      throw new Error(
        `Login failed: ${
          error.response.data.message || error.response.statusText
        }`
      );
    } else {
      console.error('Login failed:', error.message);
      throw new Error(`Login failed: ${error.message}`);
    }
  }
}
