import notFoundImage from './not-found.png';

export const NotFound: React.FC = () => {
  return (
    <div>
      <div>
        <h3>{'This page could not be found'}</h3>
      </div>
      <img src={notFoundImage} />
    </div>
  );
};
