import ModalClose from '@mui/joy/ModalClose';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import React, {useState} from 'react';
import {useCategories} from '../../contexts';
import {ProgressBar} from '../../core-component/progress-bar/ProgressBar';
import {getCategories} from '../../service/getCategories';
import {setNewRows} from '../../service/setNewRows';
import {updateCurrentRow} from '../../service/updateCurrentRow';
import {categoriesURL} from '../../service/urls';
import {ICategoryDTO} from '../../types';
import {MyFormik} from '../Modals/Formik';
import {initalValuesCategories} from './initialStateCategoriesInputs';
import {inputInfoCategories} from './inputInfoCategories';
import {styleCategoriesBox} from './styles';
import {validationSchemaCategories} from './validationScemaCategories';

interface EditCategoryModalProps {
  isOpen: boolean;
  onClose: () => void;
  isNew: boolean;
  id: string;
}

const EditCategoryModal: React.FC<EditCategoryModalProps> = ({
  isOpen,
  onClose,
  isNew,
  id,
}) => {
  const {categories, setCategories} = useCategories();
  const [isLoading, setIsLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const category: ICategoryDTO | undefined = categories?.find(
    (c) => c.id === id
  );
  // let imageUrls: any = isNew ? [] : category?.images;
  // const [imagesArr, setImagesArr] = useState<any[]>(imageUrls);

  const handleFormik = async (newValue: any) => {
    setIsLoading(true);
    if (!isNew) {
      try {
        await updateCurrentRow(categoriesURL, {
          ...newValue,
        });

        setCategories!(await getCategories());
        setTimeout(onClose, 1000);
        setSuccess(true);
      } catch (err) {
        console.error('bad request');
        setSuccess(false);
      }
    } else {
      try {
        await setNewRows(categoriesURL, {
          ...newValue,
        });
        setCategories!(await getCategories());
        setTimeout(onClose, 1000);
        setSuccess(true);
      } catch (err) {
        console.error('bad request');
        setSuccess(false);
      }
    }
    setIsLoading(false);
  };

  return (
    <Modal open={isOpen} onClose={onClose}>
      <Box sx={styleCategoriesBox} className={'edit-category-modal'}>
        <ProgressBar isLoading={isLoading} success={success} />
        <MyFormik
          onClose={onClose}
          disabled={isLoading}
          initialValues={isNew ? initalValuesCategories : category}
          inputState={inputInfoCategories}
          validationSchema={validationSchemaCategories}
          handleFormik={handleFormik}
          key={'edit-category-modal'}
          className={'edit-category-modal__formik'}
        />
        <ModalClose onClick={onClose} />
      </Box>
    </Modal>
  );
};

export {EditCategoryModal};
