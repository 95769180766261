import * as Yup from "yup";

export const validationSchemaProducts = Yup.object().shape({
  name: Yup.string()
    .required("Name is required")
    .min(3, "Name must be at least 3 letters"),
  description: Yup.string().required("Description is required"),
  price: Yup.number()
    .typeError("Must be a number")
    .required("This field is required")
    .integer("Must be an integer")
    .min(0, "Must be greater than or equal to 0")
    .test("is-num", "Must be a valid number", (value) => !isNaN(value)),
  categoryId: Yup.string().required("Name is required"),
  quantity: Yup.number()
    .typeError("Must be a number")
    .required("This field is required")
    .integer("Must be an integer")
    .min(0, "Must be greater than or equal to 0")
    .test("is-num", "Must be a valid number", (value) => !isNaN(value)),
});
