import axios from 'axios';

const addAuthToken = (token: string): void => {
  axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
};

const removeAuthToken = (): void => {
  delete axios.defaults.headers.common['Authorization'];
};

export const setAuthToken = (token: string): void => {
  if (token) {
    addAuthToken(token);
  } else {
    removeAuthToken();
  }
};
