import React from 'react';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';

import './CustomInputs.css';
import {ErrorMessage, Field} from 'formik';
import {CustomFieldPropsDTO} from '@/types';
import {useCategories} from '../../../contexts';

export const CustomSelector: React.FC<CustomFieldPropsDTO> = ({
  error,
  info,
  disabled,
}) => {
  const {categories} = useCategories();

  return (
    <div className="custemInput">
      <FormControl sx={{m: 1, width: '100%'}} error={Boolean(error)}>
        <InputLabel>{info.label}</InputLabel>
        <Field disabled={disabled} as={Select} {...info} variant="standard">
          {categories!.map((categories) => {
            return (
              <MenuItem key={categories.id} value={categories.id}>
                {categories.name}
              </MenuItem>
            );
          })}
        </Field>
      </FormControl>
      <ErrorMessage className="error" name={info.name} component="div" />
    </div>
  );
};
