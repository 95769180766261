import {SnackbarAlertDTO} from '@/types';
import {PropsWithChildren, createContext, useState} from 'react';
import {Snackbar} from '../core-component/snackbar/Snackbar';

const SnackbarContext = createContext<{
  showSnackbar?: (message: string, severity?: string) => void;
}>({});

const SnackbarContextProvider: React.FC<PropsWithChildren> = ({children}) => {
  const [alert, setAlert] = useState<SnackbarAlertDTO>({
    message: '',
    severity: '',
    open: false,
  });

  const showSnackbar = (message: string, severity: string = 'info') => {
    setAlert({message, severity, open: true});
  };

  const handleClose = (): void => {
    setAlert((prev) => ({...prev, open: false}));
  };

  return (
    <SnackbarContext.Provider value={{showSnackbar}}>
      {children}
      <Snackbar
        open={alert.open}
        message={alert.message}
        severity={alert.severity}
        onClose={handleClose}
      />
    </SnackbarContext.Provider>
  );
};

export {SnackbarContext, SnackbarContextProvider};
