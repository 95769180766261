import './products-tab.css';
import React, {useState} from 'react';

import {useProducts} from '../../contexts';
import {itemsURL} from '../../service/urls';
import {DataGrid} from '../dataGrid/DataGrid';
import {getItems} from '../../service/getItems';
import {EditProductModal} from './EditProductModal';
import {ColumnsProduct} from '../dataGrid/products/columns';
import {removeCurrentRow} from '../../service/removeCurrentRow';

const ProductsTab: React.FC = () => {
  const [isProductModalOpen, setIsProductModalOpen] = useState<boolean>(false);
  const [isNew, setIsNew] = useState<boolean>(false);
  const [currentId, setCurrentId] = useState<string>('');
  const {products, setProducts, getProductsWithCategoryNames} = useProducts();

  const editProduct = (id: string): void => {
    setIsProductModalOpen(true);
    setCurrentId(id);
  };

  const handleRemoveItem = async (id: string): Promise<void> => {
    try {
      await removeCurrentRow(itemsURL, id);
      const updatedProducts = getProductsWithCategoryNames!(await getItems());

      setProducts!(updatedProducts!);
    } catch (err) {
      console.error(err);
    }
  };

  const addProduct = (): void => {
    setIsProductModalOpen(true);
    setIsNew(true);
  };

  const closeEditProductModal = (): void => {
    setIsProductModalOpen(false);
    setIsNew(false);
  };

  return (
    <>
      <DataGrid
        removeItem={handleRemoveItem}
        rows={products!}
        addItem={addProduct}
        editItem={editProduct}
        getColumns={ColumnsProduct}
      />
      {isProductModalOpen && (
        <EditProductModal
          id={currentId}
          isOpen={isProductModalOpen}
          onClose={closeEditProductModal}
          isNew={isNew}
        />
      )}
    </>
  );
};

export {ProductsTab};
